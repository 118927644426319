@tailwind base;
@tailwind components;
@tailwind utilities;

:root {
    --MainFont: 'Anton', sans-serif;
    --SecondFont: 'Montserrat', sans-serif;
    --MainColor: #be0d1e;
    --secondColor: #1384ef;
    --baseColor: #000;
}


* {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
}


.mainFont {
    font-family: var(--MainFont);
}

.secondFont {
    font-family: var(--SecondFont);
}

.mainColor {
    color: var(--MainColor);
}

.secondColor {
    color: var(--secondColor);
}

.mainBg {
    background-color: var(--MainColor);
}

.secondBg {
    background-color: var(--secondColor);
}

.baseBg {
    background-color: var(--baseColor);
}

.mainBorder {
    border: 1px solid var(--MainColor);
}

.secondBorder {
    border: 1px solid var(--secondColor);
}

.baseBorder {
    border: 1px solid var(--baseColor);
}


.bold {
    font-weight: bold;
}

.light {
    font-weight: 300;
}

.white {
    color: white;
}

*:focus {
    outline: none;
}

.ultraSmall {
    font-size: 0.5rem;
}

.small {
    font-size: 0.8rem;
}

.white-bg {
    background-color: white !important;
}